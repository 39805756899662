.PageBar {
    background-color: #002F56;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}



.pagebar-logo-icon {
    margin: 10px;
}

.pagebar-title {
    font-size: 14pt;
    line-height: 16pt;
    font-family: 'Helvetica Neue';
    font-style: normal;
    text-align: center;
    font-weight: bold;
    margin: 10px;
    width: 230px;
    align-self: center;
}


.pagebar-icon svg {
    background-color: #66829a;
    border-radius: 15%;
    color: white
}


