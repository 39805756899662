.RefItem {
    display: flex;
    flex-direction: column;
}

#ref-item-card-header {
    padding-bottom: 5px;
}
#ref-item-card-header .MuiTypography-root {
    font-size: 16pt;
    line-height: 19pt;
    font-family: 'Helvetica Neue';
    text-align: left;
    margin: 5px;
    font-weight: bold !important;
}

#ref-item-card {
    border-radius: 10px !important;
    border: 1px solid black !important;
    /* height: calc(100vh - 155px) !important; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    flex:1;
    display: flex;
    flex-direction: column;
}

.line-break {
    border-top: 5px solid #EBB54C;
    width: 95%;
}

/* config */

.align-left {
    text-align: left;
}

.reference-header-logo {
    width: 12vmin;
    height: 12vmin;
    top: 3vmin;
    left: 3vmin;
    position: absolute;
    z-index: 1000;
}

/* @font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 200;
  src:
    local('Avenir'),
    local('AvenirLTStd'),
    url(./fonts/AvenirLTStd-Light.otf) format('otf'),
} */

div, h1, h2, h3 {
    font-family: 'Avenir', sans-serif !important;
}

.alt-screens-holder {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    pointer-events: none;

}

.title-holder {
    height: 40vh;
    width: 75vw;
    left: 12.5vw;
    position: absolute;
    top: 40vh;

}

.App-title {
  font-size: 1.5em;
}

.title-background {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: absolute;
    background: -webkit-radial-gradient(#fafafa 30%, #cfcccd);
    background: -o-radial-gradient(#fafafa 30%, #cfcccd);
    background: radial-gradient(#fafafa 30%, #cfcccd);
    overflow: hidden;
    opacity: 1;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.close-button {
    position: absolute;
    top: 4vmin;
    right: 4vmin;
    height: 5vmin;
    width: 5vmin;
    background-color: rgba(255,255,255,0);
    outline: none;
    border: none;
    -webkit-transform: rotateZ(45deg);
        -ms-transform: rotate(45deg);
            transform: rotateZ(45deg);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    pointer-events: all;
    z-index: 2000;
    margin: 0;
    padding: 0;
}

img.close-inner {
    height: 5vmin;
    width: 5vmin;
    top: 0;
    left: 0;
    position: absolute;
}

.close-button.invisible {
    opacity: 0;
    -webkit-transform: rotateZ(180deg);
        -ms-transform: rotate(180deg);
            transform: rotateZ(180deg);
    right: -10vmin;
}

.title-background.invisible {
    opacity: 0;
}


.app-intro-italic {
  line-height: 110%;
  font-weight: 900;
  font-style: italic;
  color: #f4b42e;

}

.app-intro {
  line-height: 110%;
  font-weight: 900;
  background: -webkit-gradient(linear, left top, left bottom, from(#3364aa), to(#26497c));
  background: -webkit-linear-gradient(#3364aa, #26497c);
  background: -o-linear-gradient(#3364aa, #26497c);
  background: linear-gradient(#3364aa, #26497c);
  background-clip: text !important;
  -webkit-background-clip: text !important;
  color: transparent;
  -webkit-text-fill-color: transparent;
  width: 100%;
}

.menu-header-holder {
    top: 1vh;
    position: absolute;
    left: 20vw;
    width: 60vw;
    height: 8vh;
    pointer-events: none;
}

.menu-header {
    color: #3364aa;
    font-weight: 900;
    text-transform: uppercase;
    -webkit-transition: opacity 0.3s 0s ease;
    -o-transition: opacity 0.3s 0s ease;
    transition: opacity 0.3s 0s ease;
}

.menu-header.hidden {
    opacity: 0;
}

.buttons-holder {
    top: 60vh;
    height: 30vh;
    width: 100vw;
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

img.title-image-text {
    width: 100%;
    height: auto;
    margin-top: 3vmin;
    pointer-events: none;
}

button.home-menu-button {
    height: 10vw;
    width: 55vw;
    border-radius: 3vh;
    border: 2px solid #3364aa;
    font-family: 'Avenir';
    text-transform: uppercase;
    font-size: 1rem;
    outline: none;
    background-color: rgba(255,255,255,0);
    margin-top: 2vh;
    padding-top: 0.2rem;
    color: #3364aa;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
button.home-menu-button:nth-child(1) {
    background-color: #3364aa;
    color: #ffffff;
}
button.home-menu-button:nth-child(1):before {
    content: 'Slope ';
}

.menu-tray {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    /* background: url('./media/menu_tray_phone.png'); */
    background-size: 100% 100%;
    -webkit-transform: translate3d(0,75%,0);
            transform: translate3d(0,75%,0);
    pointer-events: none;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.menu-tray.visible {
    -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
            transform: translate(0,0);
    pointer-events: all;
}

.reference-menu, .links-menu {
    width: 100vw;
    height: 85vh;
    top: 15vh;
    left: 0;
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    pointer-events: none;
}

.reference-menu-button, .links-menu-button {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    text-transform: uppercase;
    width: 100vw;
    line-height: 110%;
    padding: 2vmin 1vmin 1vmin 1vmin;
    margin-bottom: 1vmin;
    font-family: 'Avenir';
    font-size: 1rem;
    border: none;
    outline: none;
    color: #fff;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    pointer-events: all;
    text-decoration: none;
}


.reference-menu-button:nth-child(1), .links-menu-button:nth-child(1) {
    -webkit-transition-delay: 0.3s;
         -o-transition-delay: 0.3s;
            transition-delay: 0.3s;
}
.reference-menu-button:nth-child(2), .links-menu-button:nth-child(2) {
    -webkit-transition-delay: 0.4s;
         -o-transition-delay: 0.4s;
            transition-delay: 0.4s;
}
.reference-menu-button:nth-child(3), .links-menu-button:nth-child(3) {
    -webkit-transition-delay: 0.5s;
         -o-transition-delay: 0.5s;
            transition-delay: 0.5s;
}
.reference-menu-button:nth-child(4), .links-menu-button:nth-child(4) {
    -webkit-transition-delay: 0.6s;
         -o-transition-delay: 0.6s;
            transition-delay: 0.6s;
}
.reference-menu-button:nth-child(5), .links-menu-button:nth-child(5) {
    -webkit-transition-delay: 0.7s;
         -o-transition-delay: 0.7s;
            transition-delay: 0.7s;
}
.reference-menu-button:nth-child(6) {
    -webkit-transition-delay: 0.8s;
         -o-transition-delay: 0.8s;
            transition-delay: 0.8s;
}
.reference-menu-button:nth-child(7) {
    -webkit-transition-delay: 0.9s;
         -o-transition-delay: 0.9s;
            transition-delay: 0.9s;
}

.reference-menu-button:nth-child(8) {
    -webkit-transition-delay: 1s;
         -o-transition-delay: 1s;
            transition-delay: 1s;
}
.reference-menu-button:nth-child(9) {
    -webkit-transition-delay: 1.1s;
         -o-transition-delay: 1.1s;
            transition-delay: 1.1s;
}
.reference-menu-button:nth-child(10) {
    -webkit-transition-delay: 1.2s;
         -o-transition-delay: 1.2s;
            transition-delay: 1.2s;
}
.reference-menu-button:nth-child(11) {
    -webkit-transition-delay: 1.3s;
         -o-transition-delay: 1.3s;
            transition-delay: 1.3s;
}

.reference-menu-button.invisible, .links-menu-button.invisible {
    -webkit-transform: translate3d(0, 80vh, 0);
            transform: translate3d(0, 80vh, 0);
    -webkit-transition: all 0s 0s linear;
    -o-transition: all 0s 0s linear;
    transition: all 0s 0s linear;
}

ol.reference-list-letter {
    list-style-type: upper-alpha;
}

ol.reference-list-number,
ul {
    padding-right: 11vmin;
}

ul > p, ol > p {
    margin-left: -4vmin;
    padding-right: -4vmin;
}

li > ul {
    padding-right: 0;
}

li {
    padding-bottom: 1rem;
}

li:last-child {
    padding-bottom: 0;
}

li p {
    margin-top: 0;
    padding-top: 0;
}

 /* .vertical-scroll {
    height: 15vh;
    overflow: scroll;
    margin-bottom: 5vh;
    position: relative;
} */

/*
.vertical-scroll:after {
    height: 25vh;
    max-width: 2000px;
    position: fixed;
    width: 100%;
    top: 53%;
    left: 0;
    content: ' ';
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), color-stop(rgba(255,255,255,1)), to(rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1));
    background: -o-linear-gradient(top, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1));
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1));
} */


/* .scrollable-icon {
    position: fixed;
    width: 6vmin;
    height: auto;
    right: 48vw;
    top: 76vh;
} */

.reference-main {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    background: #fff;
    -webkit-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    overflow-x: hidden;
    overflow-y: scroll;
    pointer-events: none;
    background: -webkit-radial-gradient(#ffffff 30%, #ffffff);
    background: -o-radial-gradient(#ffffff 30%, #ffffff);
    background: radial-gradient(#ffffff 30%, #ffffff);
}


.reference-header-holder {

    margin: 0 0 0 0 ;
    width: 100vw;
    height: 18vmin;
    left: 0vw;
    top: 0vh;
    background: #f4b42e;
    position: fixed;
    z-index: 1000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

.reference-header {
    position: relative;
    width: 70%;
    margin-top: 1vmin;
    height: 50%;
    color: #fff;
    font-family: 'Avenir';
    text-transform: uppercase;
    font-weight: 900;

}


.carousel {
    top: 10vh;
    left: 0;
    position: absolute;
    width: 100vw;
    height: 82vh;
    margin: 0;
    padding: 0;
    padding-top: 0vh;
    pointer-events: all;
}

.reference-figure {
    width: 70%;
    max-width: 400px;
    height: auto;
    z-index: 0;
    mix-blend-mode: multiply;
}

.reference-figure-holder {
    width: 100%;
    left: 0vw;
    padding: 2vmin 0;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    z-index: 0;
    background: #fff;
}
.reference-figure-holder.no-bg {
    background: rgba(255, 255, 255, 0);
}

.reference-main.invisible {
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0);
}

.reference-body {
    position: relative;
    padding: 6vmin;
    padding-top: 12vh;

}

.soil-background {
    height: 100vh;
    width: 100vw;
    left: 0;
    position: fixed;
    z-index: 1;
    pointer-events: none;
}

li.reference-body {
    padding-left: 2px;
}

.reference-body, .reference-body > * {
    /* font-family: 'adobe-caslon-pro'; */
    font-size: 1.1rem;
    text-align: left;

}

.gallery-text {
    font-size: 1rem;
    padding: 6vmin;
    text-align: left;

margin: 0;
padding-bottom: 0;
}

span.bold, p.bold {
    font-weight: bolder;
    color: #3364aa;

}

p.big {
    font-size: 2rem;
    text-align: center;
    padding-bottom: 0vmin;
    line-height: 110%;
    margin: 0;
}

.big.bold.number {
    font-size: 6rem;
    padding-top: 0;
    width: 40vw;
    border-bottom: 2px solid #3364aa;
}

.big.bold.adjust-up {
    padding-top: 0;
    margin-top: -5vh;

}

.skinny {

    font-size: 1.2rem;
}

.skinny.center {
    text-align: center;
}

.gallery-arrow-holder {
    position: absolute;
    width: 88vw;
    height: 7vw;
    pointer-events: none;
    left: 0;
    top: 92vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    padding: 0 6vmin;
    z-index: 1;
}
.gallery-arrow-holder.hide {
    opacity: 0;
}
img.gallery-arrow {
    height: 100%;
    width: auto;
}
img.gallery-arrow.right {
    opacity: 1;
}
img.gallery-arrow.left {
    -webkit-transform: rotateZ(180deg);
        -ms-transform: rotate(180deg);
            transform: rotateZ(180deg);
}

.gallery-counter {
    font-family: 'Avenir';
    font-weight: 900;
    font-size: 1.5rem;
    color: #fff;
    line-height: 100%;
}
.gallery-item-holder {
    overflow-y: auto;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: flex-start;
    -ms-flex-align: center;
        align-items: center;
    background: -webkit-radial-gradient(#ffffff 30%, #ffffff);
    background: -o-radial-gradient(#ffffff 30%, #ffffff);
    background: radial-gradient(#ffffff 30%, #ffffff);
}

/* .gallery-item-holder.tall {
    padding-top: 4vh;
}
.gallery-item-holder.taller {
    padding-top: 8vh;
} */
/* -------------------------------------------------- CALCULATOR -------------------------------------------------- */

.calculator-main {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    background: -webkit-gradient(linear, left top, left bottom, from(#88aaee), color-stop(#e2ebf0),  to(#e2ebf0));
    background: -webkit-linear-gradient(#88aaee, #e2ebf0,  #e2ebf0);
    background: -o-linear-gradient(#88aaee, #e2ebf0,  #e2ebf0);
    background: linear-gradient(#88aaee, #e2ebf0,  #e2ebf0);
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-perspective: 2000px;
            perspective: 2000px;
    pointer-events: all;
}

.calculator-main.hide{
    -webkit-clip-path: circle(0% at 50% 68%);
            clip-path: circle(0% at 50% 68%);
    pointer-events: none;
}
.calculator-main.show{
    -webkit-clip-path: circle(100% at 50% 60%);
            clip-path: circle(100% at 50% 60%);
}

.calculator-ground {
    width: 100vw;
    height: 50vh;
    bottom: 0;
    left: 0;
    position: absolute;
    background: -webkit-gradient(linear, left bottom, right top, from(#3C3B3F), to(#605C3C));
    background: -webkit-linear-gradient(bottom left, #3C3B3F, #605C3C);
    background: -o-linear-gradient(bottom left, #3C3B3F, #605C3C);
    background: linear-gradient(to top right, #3C3B3F, #605C3C);
    -webkit-transition: all 0.6s ease-out;
    -o-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
}
.depth-slider-holder {
    top: 50vh;
    height: 41vh;
    width: 100vw;
    overflow: hidden;
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
}

input.depth-slider {
    position: absolute;
    top: 19vh;
    padding: 0;
    margin: 0;
    width: 41vh;
    outline: none;
    border: none;
    -webkit-transform: rotateZ(90deg);
        -ms-transform: rotate(90deg);
            transform: rotateZ(90deg);
    height: 6vh;
    margin-top: -2vh;

}
.explainer-fade {
    pointer-events: none;
    position: absolute;
    left:0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 3000;
}

.explainer-button {
    position: absolute;
    top: 80vh;
    left: 35vw;
    height: 8vw;
    width: 30vw;
    border-radius: 6vh;
    border: 2px solid #3364aa;
    font-family: 'Avenir';
    text-transform: uppercase;
    font-size: 1rem;
    outline: none;
    background-color: #3364aa;
    margin-top: 2vh;
    padding-top: 0.2rem;
    color: #ffffff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 3000;
}

.explainer-fade.hidden, .explainer-button.hidden {
    opacity: 0;
    pointer-events: none;
}

.explainer-holder {
    position: absolute;
    left: 2.5vw;
    top: 18vh;
    width: 95vw;
    height: 95vw;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 3000;
}

.explainer-holder.hidden {
    position: absolute;
    top: 4vmin;
    left: 4vmin;
    height: 5vmin;
    width: 5vmin;
}

img.hint, img.explainer {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity 0.5s 0s linear;
    -o-transition: opacity 0.5s 0s linear;
    transition: opacity 0.5s 0s linear;
}

img.explainer.hidden {
    opacity: 0;
}

/* Dynamic Trenching Elements */
.trench-middle {
    width: 10vh;
    -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
            transform-origin: 50% 0;
    background-color: #17160f;
    height: 40vh;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    /* border-bottom: 4px solid #f4b42e; */
}
.trench-side.left {
    width: 0;
    height: 0;
    top: 0;
    -webkit-transform-origin: 100% 0%;
        -ms-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    border-top: 40vh solid #17160f;
    border-left: 40vh solid transparent;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.trench-side.right {
    width: 0;
    height: 0;
    -webkit-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    border-top: 40vh solid #17160f;
    border-right: 40vh solid transparent;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.trench-holder {
    position: absolute;
    top: 0;
    height: 40vh;
    width: 300vw;
    left: -100vw;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.trench-holder.offset {
    -webkit-transform-origin: center top;
        -ms-transform-origin: center top;
            transform-origin: center top;
    -webkit-transform: scale3d(1.02, 1, 1);
            transform: scale3d(1.02, 1, 1);
}
.trench-holder.offset .trench-middle {
    border-radius: 5px;
}

.trench-side.left.orange,.trench-side.right.orange  {
    border-top: 40vh solid #f4b42e;
}

.trench-middle.orange {
    background-color: #f4b42e;
}

.trench-holder.outline {
    -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
            transform-origin: 50% 0;
    -webkit-transform: scale(1.03, 1.03);
        -ms-transform: scale(1.03, 1.03);
            transform: scale(1.03, 1.03);
}

.trench-holder.offset.outline {
    -webkit-transform: scale(1.05, 1.03);
        -ms-transform: scale(1.05, 1.03);
            transform: scale(1.05, 1.03);
}


.calc-header-holder {
    top: 2vh;
    height: 8vh;
    width: 75vw;
    left: 12.5vw;
    position: absolute;
}

.calc-header {
    color: #fff;
    font-weight: 900;
    font-family: 'Avenir';
    text-transform: uppercase;
    line-height: 110%;
}
.calc-logo-holder {
    top: 25vh;
    height: 16vh;
    position: absolute;
    width: 100vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    -webkit-transition: all 0.6s ease-out;
    -o-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    z-index: 200;
    pointer-events: none;

}

.calc-logo {
    height: 100%;
    width: auto;
    position: relative;
    -webkit-transform: scale(1,1);
        -ms-transform: scale(1,1);
            transform: scale(1,1);
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.calc-logo.invisible {
    -webkit-transform: scale(0,0);
        -ms-transform: scale(0,0);
            transform: scale(0,0);
    opacity: 0v;
}



.calc-text-holder {
    top: 18vh;
    position: absolute;
    width: 100vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    -webkit-perspective: 2000px;
            perspective: 2000px;
    -webkit-transition: all 0.6s ease-out;
    -o-transition: all 0.6s ease-out;
    transition: all 0.6s ease-out;
    z-index: 200;
    pointer-events: none;

}

.calc-line-holder {
    width: 100vw;
    max-width: 400px ;
    position: relative;
    height: 4vh;
    margin: 0;
    padding: 0;
}

.trench-data {
    text-transform: uppercase;
    font-style: italic;
    font-size: 1rem;
    font-weight: 900;
    line-height: 100%;
    margin: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 0;
    pointer-events: none;
    bottom: 0;
}

.trench-data:nth-child(1){
    width: 60%;
    left: 5%;
    margin: 0;
    text-align: left;
    position: absolute;
}

.trench-data:nth-child(2) {
    width: 30%;
    left: 65%;
    text-align: right;
    font-size: 1.4rem;
    position: absolute;
}

.trench-data.invisible {
    opacity: 0;
    -webkit-transform: rotateZ(-8deg) scale(0.6,0.6);
        -ms-transform: rotate(-8deg) scale(0.6,0.6);
            transform: rotateZ(-8deg) scale(0.6,0.6);
}

.calc-control {

    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    padding: 0;
    padding-top: 1%;
    font-size: 1.3rem;

}


.slope-img {
    position: absolute;
    left: 8vw;
    bottom: 28vw;
    width: 20vw;
    height: 20vw;
    opacity: 0.5;
    -webkit-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

}

.calc-control-holder {
    width: 100vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    bottom: 14vmin;
    height: 8vmin;
    position: absolute;
    -ms-flex-direction: row;
        flex-direction: row;

}

.calc-arrow {
    max-height: auto;
    max-width: 6vmin;
    position: relative;


}
.calc-arrow.left {
    -webkit-transform: rotateZ(180deg);
        -ms-transform: rotate(180deg);
            transform: rotateZ(180deg);
    padding-left: 2vmin;
}
.calc-arrow.right {

    padding-left: 2vmin;
}

/* .gallery-item-holder.scroll {
    height: 100vh;
} */
.height-50vh {
    height: 100%;
}

/* .height-50vh > *:first-child {
    padding-top: 12vh;
} */
.height-50vh > *:last-child {
    padding-bottom: 2rem;
}
.align-left {
    text-align: left;
}

.landscape-blocker {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    font-family: 'Avenir';
    font-size: 2rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding: 5vmin;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/* new react-draggable slider */

.draggable-track {
    position: relative;
    top: 0vh;
    width: 85vw;
    height: 1.5vh;
    border-radius: 0.75vh;
    background-color: #664f1f;
    -webkit-box-shadow: inset 6px -5px 3px #88703e;
            box-shadow: inset 6px -5px 3px #88703e;
    -webkit-transform-origin: 0 50%;
        -ms-transform-origin: 0 50%;
            transform-origin: 0 50%;

}
.draggable-track.empty:after {
    position: absolute;
    content: '';
    left: -4px;
    top: 0vh;
    width: 1.5vh;
    height: 1.5vh;
    border-radius: 0.75vh;
    background-color: #88703e;

}
.draggable-track.empty {
    background-color: #17160f;
    -webkit-box-shadow: inset 3px -5px 3px #4b493f;
            box-shadow: inset 3px -5px 3px #4b493f;

}
.draggable-holder {
    position: absolute;
    top: 43vh;
    height: 4vh;
    left: 5vw;
    width: 90vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: left;
        justify-content: left;
}


.draggable-track-holder {
    position: absolute;
    top: 44.5vh;
    height: 40vh;
    width: 100vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;

}

.draggable-handle.visible-handle {
    height: 7vw;
    width: 7vw;
    border: 2px solid rgba(0,0,0,0.05);
    -webkit-box-shadow: 0px 3px 3px rgba(0,0,0,0.5);
            box-shadow: 0px 3px 3px rgba(0,0,0,0.5);
    border-radius: 50%;
    background-color: #f4b42e;
}

.draggable-handle .invisible-handle {
    height: 4vh;
    width: 4vh;
    border: none;
    border-radius: 50%;
    -webkit-transform: scale(6,6);
        -ms-transform: scale(6,6);
            transform: scale(6,6);
    background-color: rgba(0,0,0,0);
}



/* @media (max-width: 90vh) {
  .landscape-blocker {
      display: none;
  }

} */
