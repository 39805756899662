.menu-item {
    height: 96px;
    width: 100vw;
    background-color: #efefef;
    margin-top: 5px;
    color:black;
    display: inherit;
    align-items: center;
    justify-content: space-between;
}

.Menu {
    display: flex;
    flex: 1;
    flex-direction: column;
    z-index: 99;
    position: absolute;
    height: calc(100% - 73px);
    width: 100%;
    background-color: white;
}

.menu-item-icon {
    margin-left: 25px;
}

.menu-item-icon .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
}

.menu-item-title {
    font-size: 16pt;
    line-height: 19pt;
    font-family: 'Helvetica Neue';
    font-weight: bold;
    text-align: left;
    margin: 10px;
}

.arrow-icon {
    margin-right: 30px;
    color: #EBB54C;
    width: 50px;
    height: 50px;
}

.arrow-icon svg {
    width: 50px;
    height: 50px;
}